export const BASE_URL = 'https://www.infomailnetapp.net/';
// export const BASE_URL = 'https://www.infomailnetapp.com/back-end/';
export const API_URL = BASE_URL + 'back-end/api/';
export const ACCOUNTS_URL = API_URL + 'accounts';
export const ACTIVITIES_URL = API_URL + 'activities';
export const BANKS_URL = API_URL + 'banks';
export const BUSINESS_TYPES_URL = API_URL + 'business_types';
export const CAMPAIGNS_URL = API_URL + 'campaigns';
export const CAMPAIGN_MESSAGES_URL = API_URL + 'campaign_messages';
export const CHANNELS_URL = API_URL + 'channels';
export const CONTACTS_URL = API_URL + 'contacts';
export const GROUPS_URL = API_URL + 'groups';
export const INDUSTRIES_URL = API_URL + 'industries';
export const INTEGRATORS_URL = API_URL + 'integrators';
export const KEYWORDS_URL = API_URL + 'keywords';
export const MESSAGES_URL = API_URL + 'messages';
export const NEWS_URL = API_URL + 'news';
export const PAYMENTS_URL = API_URL + 'payments';
export const PERMISSIONS_URL = API_URL + 'permissions';
export const SCHEDULES_URL = API_URL + 'schedules';
export const SERVICES_URL = API_URL + 'services';
export const SHORT_CODES_URL = API_URL + 'short_codes';
export const SUBSCRIPTIONS_URL = API_URL + 'subscriptions';
export const USERS_URL = API_URL + 'users';
export const LOGIN_URL = USERS_URL + '/token';
export const LOGOUT_URL = USERS_URL + '/logout';
